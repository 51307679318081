body {
  font-family: Georgia,"Times New Roman",Times,serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
